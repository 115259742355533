<template>
    <v-row style="margin-top: 15px;">
        <v-col >
            <v-card class="mx-auto">
                <v-row>
					<v-col cols="12" sm="10">
						<v-card-title class="text-h6 font-weight-black">Infrared Led {{ tag_id[0] }}</v-card-title>
					</v-col>
					<v-col cols="12" sm="2">
						<v-card-actions>
						<v-btn @click="togglePause" :color="isPaused ? '#02C39A' : '#C23302'">{{ isPaused ? 'Play' : 'Pause' }}</v-btn>
					</v-card-actions>
					</v-col>
				</v-row>
                <line-chart-XYZ :options="axeXChartOptions"
                    :chartColors="yellowXChartColors" label="Infrared Led" :tag_id="tag_id" :pause="isPaused"/>
                <br>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import LineChartXYZ from "../components/LineChartMaxTry.js";


export default {
	props:["tag_id"],
    name: 'accxyz',
    components: {
			LineChartXYZ,
		},

    data() {
        return {
            yellowXChartColors: {
                borderColor: 'rgba(131, 45, 238, 0.8)',
                backgroundColor: 'rgba(131, 45, 238, 0.8)'
            },
            isPaused: false,
            id_memory : 0,
            // ===============================  CHART ACC-X ===============================
            axeXChartOptions: {
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						streaming: {
							frameRate: 30,
						}
					},
					scales: {
						xAxes: [{
							// display: 'auto',
							type: 'realtime',
                            time: {
								displayFormats: {
									millisecond: 'HH:mm:ss',
									second: 'HH:mm:ss',
									minute: 'HH:mm:ss',
									hour: 'HH:mm:ss',
									day: 'HH:mm:ss',
									week: 'HH:mm:ss',
									month: 'HH:mm:ss',
									quarter: 'HH:mm:ss',
									year: 'HH:mm:ss'
								},
								tooltipFormat: 'HH:mm:ss'
							},	
							realtime: {
								duration: 5000,
								ttl: undefined,
								refresh: 250,
								delay: 2000,
								onRefresh: (chart) => {
										this.getCanIR().then((newData) => {
										    chart.data.datasets[0].data.push(...newData);
										}).catch((e) => {
										console.log(e);
										});
								},
							}
						}],
						yAxes: [{
							type: 'linear',
							offset: true,
						}]
					},
					legend: {
						display: true
					},
				},
                
			};
		},
		methods: {
            async getCanIR() {
            const tag_id = encodeURIComponent(this.tag_id[0]);
            const url = this.$api.getRESTApiUri() + `/can_ir/last_id/${tag_id}`;

            return fetch(url)
                .then(res => res.text())
                .then((result) => {
                const data = JSON.parse(result);
                let newData = [];
                if (data[0].can_ir_led.length === 0) {
                    return [];
                }
                if (data[0].id <= this.id_memory){
                    return [];
                }
                this.id_memory = data[0].id;
                
                let somme1 = 0;
                let somme2 = 0;
                let somme3 = 0;
                let somme4 = 0;
                
                const quartTailleTableau = data[0].can_ir_led.length / 4;

                for (let i = 0; i < data[0].can_ir_led.length; i++) {
                    if (typeof data[0].can_ir_led[i] === "number") {
                    if (i < quartTailleTableau) {
                        somme1 += data[0].can_ir_led[i];
                    } else if (i < 2 * quartTailleTableau) {
                        somme2 += data[0].can_ir_led[i];
                    } else if (i < 3 * quartTailleTableau) {
                        somme3 += data[0].can_ir_led[i];
                    } else {
                        somme4 += data[0].can_ir_led[i];
                    }
                    }
                }

                const moyenne1 = quartTailleTableau > 0 ? somme1 / quartTailleTableau : 0;
                const moyenne2 = quartTailleTableau > 0 ? somme2 / quartTailleTableau : 0;
                const moyenne3 = quartTailleTableau > 0 ? somme3 / quartTailleTableau : 0;
                const moyenne4 = quartTailleTableau > 0 ? somme4 / quartTailleTableau : 0;

                newData.push(
                    {
                    x: Date.now(),
                    y: moyenne1
                    },
                    {
                    x: Date.now() + 62.5,
                    y: moyenne2
                    },
                    {
                    x: Date.now() + 125,
                    y: moyenne3
                    },
                    {
                    x: Date.now() + 187.5,
                    y: moyenne4
                    }
                );

                return newData;
                })
                .catch((error) => {
                console.log(error)
                });
            },
            togglePause() {
				this.isPaused = !this.isPaused;
			},
		}
	};
</script>