import { Line } from "vue-chartjs";
import 'chartjs-plugin-streaming';

export default {
	extends: Line,
	props: {
		label: {
			type: String
		},
		chartData: {
			type: Array
		},
		options: {
			type: Object
		},
		chartColors: {
			type: Object
		},
		tag_id_name:{
			type: Array
		},
		pause:{
			type: Boolean
		}
	},
	
	mounted() {
		const datasets = [];
		const borderColor = [
            'rgb(0, 188, 212)',
            'rgb(255, 153, 102)',
            'rgb(123, 200, 164)',
            'rgb(238, 130, 238)',
            'rgb(0, 128, 128)',
            'rgb(255, 99, 71)',
            'rgb(70, 130, 180)',
            'rgb(255, 215, 0)',
            'rgb(106, 90, 205)',
            'rgb(255, 140, 0)'
        ].reverse();
        const backgroundColor = borderColor.map(color => color.replace('rgb', 'rgba').replace(')', ', 0.2)'));

		
		for(let i =0; i < this.tag_id_name.length; i++){
			datasets.push({
				label: this.tag_id_name[i],
				data: [],
				fill: false,
				borderColor: borderColor[i],
				backgroundColor: backgroundColor[i],
				tension: 0.1,
				pointRadius: 1.0,
				borderWidth: 1.0,
			})
		}

		const chartOptions = {
			...this.options,
			plugins: {
				streaming: {
				...this.options.plugins.streaming,
				pause: this.pause,
				},
			},
			};

			this.renderChart({
			datasets: datasets
			}, chartOptions);
	},
    watch: {
        pause(newPause) {
        // Mettre à jour la valeur de l'option "pause" et mettre à jour le graphique
        this.$data._chart.config.options.plugins.streaming.pause = newPause;
        this.$data._chart.update();
        }
    },
};