import { Line } from "vue-chartjs";
import 'chartjs-plugin-streaming';

export default {
	extends: Line,
	props: {
		label: {
			type: String
		},
		chartData: {
			type: Array
		},
		options: {
			type: Object
		},
        chartColors: {
            type: Object,
        },
		pause:{
			type: Boolean
		}
	},
	
	mounted() {
        const label = this.label
		const chartOptions = {
			...this.options,
			plugins: {
				streaming: {
				...this.options.plugins.streaming,
				pause: this.pause,
				},
			},
		};
        const {
			borderColor,
			backgroundColor
		} = this.chartColors;
		this.renderChart({
				datasets: [{
					label: label,
					data: [],
					fill: false,
					borderColor: borderColor,
					backgroundColor: backgroundColor,
					tension: 0.4,
				},
				
			]
			},
			chartOptions
		);
		
	},
	watch: {
		// Lorsque la valeur de pause change, mettre à jour l'option pause du graphique
		pause(newPause) {
			this.$data._chart.config.options.plugins.streaming.pause = newPause;
			this.$data._chart.update();
		}
	},
	
};