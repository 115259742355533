<template>
    <div style="padding-left: 100px; padding-right: 100px;">
        <side-bar/>
            <v-row >
                <v-col col="12" sm="1" ></v-col>
                <v-col
                    col="12"
                    sm="3"
                    >
                    <v-select
                        v-model="tag_selected"
                        :items="tag_to_select"
                        label="Tags to view"
                        chips
                        multiple
                        persistent-hint
                        @change="data_selected = []"
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="2">
                    <v-select
                        v-model="data_selected"
                        :items="data_raw"
                        label="Raw Data"
                        multiple
                        chips
                        persistent-hint
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="2">
                    <v-select
                        v-model="data_selected"
                        :items="data_max"
                        label="Red/IR Data"
                        multiple
                        chips
                        persistent-hint
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="2"> <v-btn @click="Validate()" style="background-color: var(--vertfonce); margin-top: 20px;"> Validate </v-btn></v-col>
                <v-col cols="12" sm="2"> 
                    <v-btn @click="rechargeData()" style=" margin-top: 20px;" > <v-icon>mdi-sync</v-icon></v-btn>
                </v-col>
            </v-row>

        <accxyz v-bind:tag_id="this.tag_and_mat_selected" :tag_id_name="this.tag_selected" v-if="data_selected.includes('Acceleration') && show == true"/>
        <accxyztry v-bind:tag_id="this.tag_and_mat_selected" :tag_id_name="this.tag_selected" v-if="data_selected.includes('Acceleration') && show == true"/>
		<courbetemp v-bind:tag_id="this.tag_and_mat_selected" :tag_id_name="this.tag_selected" v-if="data_selected.includes('Temperature °C') && show == true"/>
        <canRtry v-bind:tag_id="this.tag_and_mat_selected" :tag_id_name="this.tag_selected" v-if="data_selected.includes('Red Led') && show == true"/>
        <canIRtry v-bind:tag_id="this.tag_and_mat_selected" :tag_id_name="this.tag_selected" v-if="data_selected.includes('Infrared Led') && show == true"/>
    </div>
</template>


<script>
import courbetemp from '../components/courbe-temp'
import accxyz from '../components/acc-xyz'
import accxyztry from '../components/acc-xyz-try'
import canRtry from '../components/can-r-led-try'
import canIRtry from '../components/can-ir-led-try'
import SideBar from '../components/SideBar.vue';


export default {
	props:["tag_id"],	
    components: {
		courbetemp,
		accxyz,
        accxyztry,
        canRtry,
        canIRtry,
        SideBar,
		},
    
    data() {
        return{
            tag_to_select:[],
            tag_selected: [],
            tag_and_mat_selected : [],
            tag_id_1: [],
            tag_name: [],
            data_raw: ['Temperature °C', 'Acceleration'],
            data_max:['Red Led', 'Infrared Led'],
            tags_name: [],
            data_selected: [],
            show: false,
        }
    },
    

    async mounted(){
        await Promise.all([this.getTagAvailable()])
    },
    methods:{
        async getTagAvailable(){
            const user = JSON.parse(localStorage.getItem('user'))
            this.email = user.email
            const email = encodeURIComponent(this.email);
            const url = this.$api.getRESTApiUri() + `/users/email/${email}`;
            return fetch(url)
            .then(res => res.text())
            .then(async (result) => {
                const data = JSON.parse(result);
                this.tag_id_1 = data.tag_attributed
                this.tag_name = data.tag_renamed
                for(let i=0 ; i< this.tag_id_1.length ; i++){
                if(this.tag_name[i] == "noname"){
                    this.tag_to_select.push(this.tag_id_1[i])
                }else{
                    this.tag_to_select.push(this.tag_name[i])
                }
            }
            })
            .catch(error => {
                console.log(error)
            });
        },

        async Validate(){
            for(let i = 0; i< this.tag_selected.length; i++){
                this.tag_and_mat_selected.push(this.tag_id_1[this.tag_to_select.indexOf(this.tag_selected[i])])
            }
            console.log(this.tag_and_mat_selected)
            this.show = true;
        },
        async rechargeData(){
            this.show = false;
            this.tag_selected = [];
            this.data_selected = [];
            this.tag_and_mat_selected = [];
        }
    }
}

</script>