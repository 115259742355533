<template>
   <div style="margin-top: 15px;">
        <v-col>
            <v-card class="mx-auto">
				<v-row>
					<v-col cols="12" sm="10">
						<v-card-title class="text-h6 font-weight-black">Température des Capteurs & Implants (°C)</v-card-title>
					</v-col>
					<v-col cols="12" sm="2">
						<v-card-actions>
						<v-btn @click="togglePause" :color="isPaused ? '#02C39A' : '#C23302'">{{ isPaused ? 'Play' : 'Pause' }}</v-btn>
					</v-card-actions>
					</v-col>
				</v-row>
        <line-chart :chartData="arrTemperature" :options="TemperatureChartOptions" :chartColors="turquoiseChartColors" :tag_id_name="tag_id_name"  :pause="isPaused" />
        <br>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import LineChart from "../components/LineChartMultiple.js";

export default {
	props:["tag_id", "tag_id_name"],
    components: {
			LineChart,
		},
    data() {
			return {	
				id_memo : [],	
				arrTemperature: [],
				turquoiseChartColors: {
					borderColor: 'rgb(0, 188, 212)',
					backgroundColor: 'rgba(0, 188, 212, 0.2)'
				},
				isPaused: false,
                TemperatureChartOptions: {
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						streaming: {
							frameRate: 30,
						}
					},
					scales: {
						xAxes: [{
							// display: 'auto',
							type: 'realtime',
							time: {
								displayFormats: {
									millisecond: 'HH:mm:ss',
									second: 'HH:mm:ss',
									minute: 'HH:mm:ss',
									hour: 'HH:mm:ss',
									day: 'HH:mm:ss',
									week: 'HH:mm:ss',
									month: 'HH:mm:ss',
									quarter: 'HH:mm:ss',
									year: 'HH:mm:ss'
								},
								tooltipFormat: 'HH:mm:ss'
							},	
							realtime: {
								duration: 5000,
								ttl: undefined,
								refresh: 1000,
								delay: 2000,
								onRefresh: (chart) => {
								for (let i = 0; i < this.tag_id.length; i++) {
                                    this.getTemp(i).then((newData) => {
                                        let g = JSON.parse(JSON.stringify(newData));
                                        chart.data.datasets[i].data.push(...g);
                                    }).catch((e) => {
                                        console.log("[FETCH] Error");
                                    });
                                }
								},
							}
						}],
						yAxes: [{
							type: 'linear',
							offset: true,
						}]
					},
					legend: {
						display: true
					},
				},
                
			};
		},
		methods: {
            async getTemp(i) {
				const newData = []; // Déclarer le tableau à l'extérieur de la fonction
				try {
					const id = encodeURIComponent(this.tag_id[i]);
					const url = this.$api.getRESTApiUri() + `/temperature/last_id/${id}`;
					const res = await fetch(url);
					const data = await res.json();
					if(data[0].id <= this.id_memo[i]){
						newData.push({
							x: Date.now(), 
						});
					}else{
						newData.push({
							x: Date.now(), 
							y: data[0].temperature/100, 
						});
					}
					this.id_memo[i]  = data[0].id
				} catch (error) {
					console.error(error.message);
					throw new Error('Erreur lors de l\'appel à l\'API'); // Lever une erreur générique en cas d'erreur
				}
				return newData;
			},

			togglePause() {
				this.isPaused = !this.isPaused;
			},
			
		}
	};
			
</script>