import { Line } from "vue-chartjs";
import 'chartjs-plugin-streaming';

export default {
	extends: Line,
	props: {
		label: {
			type: String
		},
		chartData: {
			type: Array
		},
		options: {
			type: Object
		},
        chartColors: {
            type: Object,
        },
		pause:{
			type: Boolean
		}
	},
	
	mounted() {
		const chartOptions = {
			...this.options,
			plugins: {
				streaming: {
				...this.options.plugins.streaming,
				pause: this.pause,
				},
			},
		};
		this.renderChart({
				datasets: [{
					label: "Axe X",
					data: [],
					fill: false,
					borderColor: 'rgba(238, 73, 1, 0.8)',
					backgroundColor: 'rgba(238, 73, 1, 0.8)',
					tension: 0.4,
				},
				{
					label: "Axe Y",
					data: [],
					fill: false,
					borderColor: 'rgba(59, 207, 40, 0.8)',
					backgroundColor: 'rgba(59, 207, 40, 0.8)',
					tension: 0.4,
				},
				{
					label: "Axe Z",
					data: [],
					fill: false,
					borderColor: 'rgba(24, 96, 209, 0.8)',
					backgroundColor: 'rgba(24, 96, 209, 0.8)',
					tension: 0.4,
				},

			]
			},
			chartOptions
		);
		
	},
	watch: {
		// Lorsque la valeur de pause change, mettre à jour l'option pause du graphique
		pause(newPause) {
			this.$data._chart.config.options.plugins.streaming.pause = newPause;
			this.$data._chart.update();
		}
	},
};