<template>
    <v-row style="margin-top: 15px;">
		<v-col >
			<v-card class="mx-auto">
				<v-row>
					<v-col cols="12" sm="12" class="row-between">
						<v-card-title class="text-h6 font-weight-black">Accélération Axe X (g)</v-card-title>
						<v-card-title> <v-btn @click="togglePause" :color="isPaused ? '#02C39A' : '#C23302'">{{ isPaused ? 'Play' : 'Pause' }}</v-btn></v-card-title>
					</v-col>
				</v-row>
				<line-chart-X :chartData="arrAccelerometreX" :options="axeXChartOptions"
					:chartColors="yellowXChartColors" label="Axe X" :tag_id_name="tag_id_name" :pause="isPaused"/>
				<br>
			</v-card>
		</v-col>
		<v-col>
			<v-card class="mx-auto">
				<v-row>
					<v-col cols="12" sm="12" class="row-between">
						<v-card-title class="text-h6 font-weight-black">Accélération Axe Y (g)</v-card-title>
						<v-card-title> <v-btn @click="togglePause" :color="isPaused ? '#02C39A' : '#C23302'">{{ isPaused ? 'Play' : 'Pause' }}</v-btn></v-card-title>
					</v-col>
				</v-row>
				<line-chart-Y :chartData="arrAccelerometreY" :options="axeYChartOptions"
					:chartColors="yellowXChartColors" label="Axe Y" :tag_id_name="tag_id_name" :pause="isPaused"/>
				<br>
				
				<v-divider></v-divider>
			</v-card>
		</v-col>
		<v-col>
			<v-card class="mx-auto">
				<v-row>
					<v-col cols="12" sm="12" class="row-between">
						<v-card-title class="text-h6 font-weight-black">Accélération Axe Z (g)</v-card-title>
						<v-card-title> <v-btn @click="togglePause" :color="isPaused ? '#02C39A' : '#C23302'">{{ isPaused ? 'Play' : 'Pause' }}</v-btn></v-card-title>
					</v-col>
				</v-row>
				<line-chart-Z :chartData="arrAccelerometreZ" :options="axeZChartOptions"
					:chartColors="yellowXChartColors" label="Axe Z" :tag_id_name="tag_id_name" :pause="isPaused"/>
				<br>
				<v-divider></v-divider>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import LineChartX from "../components/LineChartXMultiple.js";
import LineChartY from "../components/LineChartXMultiple.js";
import LineChartZ from "../components/LineChartXMultiple.js";


export default {
	props:["tag_id", "tag_id_name"],
    name: 'accxyz',
    components: {
			LineChartX,
			LineChartY,
			LineChartZ,	
	},

    data() {
        return {
			id_memo_x: [],
			id_memo_y: [],
			id_memo_z: [],
			isPaused: false,
            arrAccelerometreX: [],
            arrAccelerometreY: [],
            arrAccelerometreZ: [],
            yellowXChartColors: {
                borderColor: 'rgba(238, 73, 1, 0.8)',
                backgroundColor: 'rgba(238, 73, 1, 0.8)'
            },
            // ===============================  CHART ACC-X ===============================
            axeXChartOptions: {
					parsing: false,
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						zoom: {
							pan: {
								enabled: true,
								mode: 'x',
							},
							zoom: {
								wheel: true,
								enabled: true,
								mode: 'x'
							}
						},
						streaming: {
							frameRate: 20,
						}
					},
					scales: {
						xAxes: [{
							// display: 'auto',
							type: 'realtime',
							time: {
								displayFormats: {
									millisecond: 'HH:mm:ss',
									second: 'HH:mm:ss',
									minute: 'HH:mm:ss',
									hour: 'HH:mm:ss',
									day: 'HH:mm:ss',
									week: 'HH:mm:ss',
									month: 'HH:mm:ss',
									quarter: 'HH:mm:ss',
									year: 'HH:mm:ss'
								},
								tooltipFormat: 'HH:mm:ss'
							},	
							realtime: {
								duration: 5000,
								ttl: undefined,
								refresh: 250,
								delay: 2000,
								onRefresh: (chart) => {
									for (let i = 0; i < this.tag_id.length; i++) {
										this.getAxeX(i).then((newData) => {
											let g = JSON.parse(JSON.stringify(newData));
											chart.data.datasets[i].data.push(...g);
										}).catch((e) => {
											console.log("[FETCH] Error");
										});
									}
										
								},
							}
						}],
						yAxes: [{
							type: 'linear',
							offset: true,
						}]
					},
					legend: {
						display: true
					},
				},
				// ===============================  CHART AXE Y ===============================
				axeYChartOptions: {
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						streaming: {
							frameRate: 20,
						}
					},
					scales: {
						xAxes: [{
							// display: 'auto',
							type: 'realtime',
							time: {
								displayFormats: {
									millisecond: 'HH:mm:ss',
									second: 'HH:mm:ss',
									minute: 'HH:mm:ss',
									hour: 'HH:mm:ss',
									day: 'HH:mm:ss',
									week: 'HH:mm:ss',
									month: 'HH:mm:ss',
									quarter: 'HH:mm:ss',
									year: 'HH:mm:ss'
								},
								tooltipFormat: 'HH:mm:ss'
							},	
							realtime: {
								duration: 5000,
								ttl: undefined,
								refresh: 250,
								delay: 2000,
								onRefresh: (chart) => {
										for (let i = 0; i < this.tag_id.length; i++) {
										this.getAxeY(i).then((newData) => {
											let g = JSON.parse(JSON.stringify(newData));
											chart.data.datasets[i].data.push(...g);
										}).catch((e) => {
											console.log("[FETCH] Error");
										});
									}
								},
							}
						}],
						yAxes: [{
							type: 'linear',
							offset: true,
						}]
					},
					legend: {
						display: true
					},
				},
				// ===============================  CHART AXE Z ===============================
				axeZChartOptions: {
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						streaming: {
							frameRate: 20,
						}
					},
					scales: {
						xAxes: [{
							// display: 'auto',
							type: 'realtime',
							time: {
								displayFormats: {
									millisecond: 'HH:mm:ss',
									second: 'HH:mm:ss',
									minute: 'HH:mm:ss',
									hour: 'HH:mm:ss',
									day: 'HH:mm:ss',
									week: 'HH:mm:ss',
									month: 'HH:mm:ss',
									quarter: 'HH:mm:ss',
									year: 'HH:mm:ss'
								},
								tooltipFormat: 'HH:mm:ss'
							},	
							realtime: {
								duration: 5000,
								ttl: undefined,
								refresh: 250,
								delay: 2000,
								onRefresh: (chart) => {
									for (let i = 0; i < this.tag_id.length; i++) {
										this.getAxeZ(i).then((newData) => {
											let g = JSON.parse(JSON.stringify(newData));
											chart.data.datasets[i].data.push(...g);
										}).catch((e) => {
											console.log("[FETCH] Error");
										});
									}
								},
							}
						}],
						yAxes: [{
							type: 'linear',
							offset: true,
						}]
					},
					legend: {
						display: true
					},
				},
                
			};
		},
		methods: {
			// async getAxeX(i) {
			// 	const tag_id = encodeURIComponent(this.tag_id[i]);
			// 	const url= this.$api.getRESTApiUri() + `/axe_x/last_id/${tag_id}`;
			// 	return fetch(url)
			// 		.then(res => res.text())
			// 		.then((result) => {
			// 			const data = JSON.parse(result);
			// 			let newData = [];
			// 			if (data[0].acc_axe_x.length === 0) {
			// 				return [];
			// 			}
			// 			if(data[0].id <= this.id_memo_x[i]){
			// 				newData.push({
			// 					x: Date.now(), 
			// 				});
			// 			}else{
			// 				let somme1 = 0;
			// 				let somme2 = 0;
			// 				for (let i = 0; i < data[0].acc_axe_x.length; i++) {
			// 				if (typeof data[0].acc_axe_x[i] === "number") {
			// 					if (i < data[0].acc_axe_x.length / 2) {
			// 						somme1 += data[0].acc_axe_x[i];
			// 					} else {
			// 						somme2 += data[0].acc_axe_x[i];
			// 					}
			// 				}}
			// 				const tailleMoitieTableau = data[0].acc_axe_x.length / 2;
			// 				const moyenne1 = tailleMoitieTableau > 0 ? somme1 / tailleMoitieTableau : 0;
			// 				const moyenne2 = tailleMoitieTableau > 0 ? somme2 / tailleMoitieTableau : 0;
			// 				newData.push(
			// 				{
			// 					x: Date.now(),
			// 					y: moyenne1
			// 				},
			// 				{
			// 					x: Date.now() + 125,
			// 					y: moyenne2
			// 				}
			// 				);
			// 			}
			// 			this.id_memo_x[i] = data[0].id
			// 			return newData;
			// 		})
			// 		.catch((error) => {
			// 			console.log(error)
			// 		});
			// },
			async getAxeX(i) {
				const tag_id = encodeURIComponent(this.tag_id[i]);
				const url= this.$api.getRESTApiUri() + `/axe_x/last_id/${tag_id}`;
				return fetch(url)
					.then(res => res.text())
					.then((result) => {
						const data = JSON.parse(result);
						let newData = [];
						if (data[0].acc_axe_x.length === 0) {
							return [];
						}
						if(data[0].id <= this.id_memo_x[i]){
							newData.push({
								x: Date.now(), 
							});
						}else{
							const frq = 250/data[0].acc_axe_x.length
							for(let i = 0; i<data[0].acc_axe_x.length; i = i+4){
								newData.push({
									y: data[0].acc_axe_x[i],
									x: Date.now() + i*frq, 
								});
							}
						}
						this.id_memo_x[i] = data[0].id
						return newData;
					})
					.catch((error) => {
						console.log(error)
					});
			},
			async getAxeY(i) {
				const tag_id = encodeURIComponent(this.tag_id[i]);
				const url= this.$api.getRESTApiUri() + `/axe_y/last_id/${tag_id}`;
				return fetch(url)
					.then(res => res.text())
					.then((result) => {
						const data = JSON.parse(result);
						let newData = [];
						if (data[0].acc_axe_y.length === 0) {
							return [];
						}
						if(data[0].id <= this.id_memo_y[i]){
							newData.push({
							x: Date.now(), 
							});
						}else{
							const frq = 250/data[0].acc_axe_y.length
							for(let i = 0; i<data[0].acc_axe_y.length; i = i+4){
								newData.push({
									y: data[0].acc_axe_y[i],
									x: Date.now() + i*frq, 
								});
							}
						}
						this.id_memo_y[i]  = data[0].id
						
						
						return newData;
					})
					.catch((error) => {
						console.log(error)
					});
			},
			async getAxeZ(i) {
				const tag_id = encodeURIComponent(this.tag_id[i]);
				const url= this.$api.getRESTApiUri() + `/axe_z/last_id/${tag_id}`;
				return fetch(url)
					.then(res => res.text())
					.then((result) => {
						const data = JSON.parse(result);
						let newData = [];
						if (data[0].acc_axe_z.length === 0) {
							return [];
						}
						if(data[0].id <= this.id_memo_z[i]){
							newData.push({
							x: Date.now(), 
							});
						}else{
							const frq = 250/data[0].acc_axe_z.length
							for(let i = 0; i<data[0].acc_axe_z.length; i = i+4){
								newData.push({
									y: data[0].acc_axe_z[i],
									x: Date.now() + i*frq, 
								});
							}
						}
						this.id_memo_z[i]  = data[0].id
						
						
						return newData;
					})
					.catch((error) => {
						console.log(error)
					});
			},

			togglePause() {
				this.isPaused = !this.isPaused;
			},
            
		}
	};
</script>